import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import logoWhite from "../images/enimeris-logo-website-white.png";
import img404 from "../images/404.png";

function NotFoundPage() {
    return (
        <div>
            <div style={{ backgroundColor: "#143b68" }}>
                <Link to={"/"} className="py-6 block">
                    <img className="w-56 md:w-64 m-auto" src={logoWhite} alt="white-logo" />
                </Link>
            </div>
            <div className="error-page-container">
                <div className="flex flex-wrap justify-center">
                    <img className="w-56 md:w-64 m-auto" src={img404} alt="404" />
                        <React.Fragment>
                            <h1 className="py-8 text-xl w-full text-center" style={{ color: "#77838F" }}>ΩΧΧΧ! Η ΣΕΛΙΔΑ ΠΟΥ ΠΡΟΣΠΑΘΕIΤE ΝΑ ΔΕΙΤΕ ΔΕΝ ΜΠΟΡΕΙ ΝΑ ΒΡΕΘΕΙ.</h1>
                            <Link to={"/"} className="cf-bttn" style={{ backgroundColor: "#143b68", marginTop: 0 }}>
                                Επιστροφή στις Θέσεις Εργασίας
                            </Link>
                        </React.Fragment>
                </div>
            </div>
        </div>
    )
}


export default NotFoundPage
